<template>
    <div class="card">
      <div class="card-body">
        <!-- Spline Area chart -->
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h4 class="card-title">Hour Trend</h4>
          <div class="d-flex align-items-center overall-count">
            <div class="mr-2">Views</div>
            <switches
              v-model="viewers"
              type-bold="true"
              color="success"
              class="m-0"
            ></switches>
            <div class="ml-2">Viewers</div>
          </div>
        </div>
        <VueApexCharts
          class="apex-charts"
          height="350"
          type="area"
          dir="ltr"
          :series="series"
          :options="chartOptions"
          :key="key"
        ></VueApexCharts>
      </div>
    </div>
  </template>
  <script>
  import appConfig from "@/app.config";
  import VueApexCharts from "vue-apexcharts";
  import filterMixins from "../../../mixins/filterData";
  import Switches from "vue-switches";

  export default {
    props: ["filters"],
    mixins: [filterMixins],
    components: {
      VueApexCharts,
      Switches,
    },
    data() {
      return {
        title: "Live Registrations",
        series: [
          {
            name: "Articles",
            data: [],
          },
          {
            name: "Cases",
            data: [],
          },
          {
            name: "Newsletter",
            data: [],
          },
          {
            name: "Video",
            data: [],
          },
        ],
        chart: {
          toolbar: {
            show: false,
          },
        },
        chartOptions: {
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            width: 3,
          },
          colors: ["#884EA0", "#148F77", "#B7950B","#A04000"],
          xaxis: {
            type: "date",
            categories: ["Januaray"],
          },
        },
        key: 0,
        loading: true,
        cases_hour: [],
        viewers: true,
      };
    },
    watch: {
      filters: {
        deep: true,
        handler() {
          this.getHourTrend();
        },
      },
      viewers() {
        this.getHourTrend();
      },
    },
    created() {
      // this.init();
    },
    methods: {
      init() {
        // this.loading = true;
        // this.loading = false;
      },
      async getHourTrend() {
        const searchfiltersParams = this.getCleanFilteredData(this.filters);
        try {
          const response = await this.$http.get(
            appConfig.api_base_url + "/forum/hourTrend",
            {
              params: searchfiltersParams,
            }
          );
          this.cases_hour = response.data.cases_hour_trend;
          const compressed = this.cases_hour.reduce((a, c) => {
            const dateIndex = a.findIndex(
              (o) => o.hour === c.hour && (c.hour != null || c.hour != "null" || c.hour != "")
            );
            if (dateIndex !== -1) {
              a[dateIndex].views += c.views;
              a[dateIndex].viewers += c.viewers;
            } else {
              a.push({
                hour: c.hour,
                views: c.views,
                viewers: c.viewers,
              });
            }
            return a;
          }, []);
          this.cases_hour = compressed;
          let time = [];
          this.chartOptions.xaxis.categories = [];
          for (let index = 0; index < 24; index++) {
            this.chartOptions.xaxis.categories.push(
              "" + index + ":00 - " + (parseInt(index) + 1) + ":00"
            );
            time.push(index);
          }
          this.series[0].data = [];
          this.series[1].data = [];
          this.series[2].data = [];
          this.series[3].data = [];
          time.forEach((c) => {
            let d = response.data.articles_hour_trend.filter((v) => v.hour == c);
            if(this.viewers == false) {
              let views = d.length > 0 ? d[0].total_views : 0;
              this.series[0].data.push(views);
            } else {
              let viewers = d.length > 0 ? d[0].total_viewers : 0;
              this.series[0].data.push(viewers);
            }
            let f = this.cases_hour.filter((v) => v.hour == c);
            if(this.viewers == false) {
              let views = f.length > 0 ? f[0].views : 0;
              this.series[1].data.push(views);
            } else {
              let viewers = f.length > 0 ? f[0].viewers : 0;
              this.series[1].data.push(viewers);
            }
            let n = response.data.newsletter_hour_trend.filter((v) => v.hour == c);
            if(this.viewers == false) {
              let views = n.length > 0 ? n[0].total_views : 0;
              this.series[2].data.push(views);
            } else {
              let viewers = n.length > 0 ? n[0].total_viewers : 0;
              this.series[2].data.push(viewers);
            }
            let v = response.data.video_hour_trend.filter((v) => v.hour == c);
            if(this.viewers == false) {
              let views = v.length > 0 ? v[0].total_views : 0;
              this.series[3].data.push(views);
            } else {
              let viewers = v.length > 0 ? v[0].total_viewers : 0;
              this.series[3].data.push(viewers);
            }
          });
          this.key += 1;
        } catch (err) {
          console.log(err);
        }
      },
    },
    mounted() {
      this.getHourTrend();
    },
  };
  </script>
  