<template>
    <div class="card">
      <div class="card-body">
        <!-- <div class="d-flex justify-content-between align-items-center"> -->
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="card-title m-0">Viewers</h4>
          <JsonExcel
            class="btn p-0"
            :data="content"
            name="content.csv"
            type="csv"
          >
            <i class="mdi mdi-download"></i>
            Download
          </JsonExcel>
        </div>
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <div class="table-responsive">
            <b-table
                :items="content"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered">
                <template v-slot:cell(is_prime)="row">
                    <i
                    class="fas fa-check-circle"
                    style="color: #a4a4a4"
                    v-if="row.item.is_prime !== 1"
                    ></i>
                    <i class="fas fa-check-circle" style="color: #1cbb8c" v-else></i>
                </template>
            </b-table>
        </div>
        <div class="row">
            <div class="col">
                <div class="dataTables_pa ginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    ></b-pagination>
                </ul>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
    <script>
  import appConfig from "@/app.config";
  import filterMixins from "../../../mixins/filterData";
  import JsonExcel from "vue-json-excel";
  export default {
    props: ["filters"],
    mixins: [filterMixins],
    components: {
      JsonExcel,
    },
    data() {
      return {
        content: [],
        isSearched: false,
        doctorName: "",
        mobileNumber: null,
        overallCount: false,
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50],
        filter: null,
        filterOn: [],
        sortBy: "",
        sortDesc: false,
        fields: [
          { key: "doctor", sortable:true, label: "Doctor" },
          { key: "mobile_number", sortable:true, label: "Mobile" },
          { key: "email", sortable:true, label: "Email" },
          { key: "speciality", sortable:true, label: "Speciality"},
          { key: "city", sortable:true, label: "City" },
          { key: "state", sortable:true, label: "State" },
          { key: "is_prime", sortable:true, label: "Prime" },
          { key: "articleCounts", sortable:true, label: "Articles Viewed"},
          { key: "caseCounts", sortable:true, label: "Cases Viewed"},
          { key: "newsletterCounts", sortable:true, label: "Newsletters Viewed"},
          { key: "videoCounts", sortable:true, label: "Videos Viewed"},
        ],
      };
    },
    watch: {
      filters: {
        deep: true,
        handler() {
          this.getViewersList();
        },
      },
    },
    computed: {
      /* Total no. of records */
      rows() {
        return this.content.length;
      },
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getViewersList() {
            const searchfiltersParams = this.getCleanFilteredData(this.filters);
            try {
            const response = await this.$http.get(
                appConfig.api_base_url + "/forum/content-viewers",
                {
                params: searchfiltersParams,
                }
            );
            this.content = response.data.viewers;
            this.totalRows = this.content.length;
            } catch (err) {
            console.log(err);
            }
        },
    },
    mounted() {
      this.getViewersList();
    },
  };
  </script>
    