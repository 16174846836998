<template>
    <div class="row">
        <div class="col-md-3">
            <div class="card">
                <div class="card-body pb-0 pt-2">
                    <h4 class="card-title">Total Users</h4>
                    <h3>{{ count.members_total_count }}</h3>
                </div>
                <!-- <div class="card-body py-2">
                    <div class="">
                    </div>
                </div> -->
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-body pb-0 pt-2">
                    <h4 class="card-title">DAU</h4>
                    <h3>{{ count.distinct_users_count }}</h3>
                </div>
                <!-- <div class="card-body border-top py-2">
                    <div class="">
                    <template v-if="statsData[0].subvalue">
                        <span
                        class="badge font-size-11"
                        :class="
                            statsData[0].subvalue > 0
                            ? 'badge-soft-success'
                            : 'badge-soft-danger'
                        "
                        >
                        <i
                            class="mdi"
                            :class="
                            statsData[0].subvalue > 0 ? 'mdi-menu-up' : 'mdi-menu-down'
                            "
                        ></i>
                        {{ statsData[0].subvalue }} %
                        </span>
                        <span class="text-muted ml-2">{{ statsData[0].subtext }}</span>
                    </template>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-body pb-0 pt-2">
                    <h4 class="card-title">MAU</h4>
                    <h3>{{ count.monthly_distinct_users_count }}</h3>
                </div>
                <!-- <div class="card-body border-top py-2">
                    <div class="">
                    <template v-if="statsData[1].subvalue">
                        <span
                        class="badge font-size-11"
                        :class="
                            statsData[1].subvalue > 0
                            ? 'badge-soft-success'
                            : 'badge-soft-danger'
                        "
                        >
                        <i
                            class="mdi"
                            :class="
                            statsData[1].subvalue > 0 ? 'mdi-menu-up' : 'mdi-menu-down'
                            "
                        ></i>
                        {{ statsData[1].subvalue }} %
                        </span>
                        <span class="text-muted ml-2">{{ statsData[1].subtext }}</span>
                    </template>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- <div class="col-md-3">
          <div class="card">
            <div class="card-body pb-0 pt-2">
              <div class="d-flex justify-content-between">
                <h4 class="card-title">Inactive Users</h4>
                <multiselect
                  v-model="value" 
                  :options="optionsRange" 
                  :close-on-select="true"
                  :show-labels="false"
                  label="title" 
                  track-by="id"
                  @input="changeRange()">
                </multiselect>
              </div>
            </div>
          </div>
        </div> -->
    </div>
</template>

<script>
import appConfig from "@/app.config";
import filterMixins from "../../../mixins/filterData";
// import Multiselect from "vue-multiselect";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    // Multiselect,
  },
  data() {
    return {
        value: null,
        optionsRange: [
            {
                id: 2,
                title: 'Last 7 Days',
            },
            {
                id: 3,
                title: 'Last 15 Days',
            }
        ],
        count: 0,
        statsData: [
            {
            subvalue: 0,
            subtext: "From Previous Period",
            },
            {
            subvalue: 0,
            subtext: "From Previous Period",
            },
        ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getCount();
      },
    },
  },
  methods: {
    // changeRange () {
    //     console.log('hello');
    // },
    async getCount() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/forum/count",
          {
            params: searchfiltersParams,
          }
        );
        this.count = response.data;
        this.statsData[0].subvalue =
          this.count.previous_period_count !== 0
            ? (
                ((this.count.count - this.count.previous_period_count) /
                  this.count.previous_period_count) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[0].subvalue = !isNaN(this.statsData[0].subvalue)
          ? this.statsData[0].subvalue
          : "0";

        this.statsData[1].subvalue =
          this.count.previous_period_prime_count !== 0
            ? (
                ((this.count.prime_count -
                  this.count.previous_period_prime_count) /
                  this.count.previous_period_prime_count) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[1].subvalue = !isNaN(this.statsData[1].subvalue)
          ? this.statsData[1].subvalue
          : "0";
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getCount();
  },
};
</script>
