<template>
    <div class="card">
      <div class="card-body">
        <!-- <div class="d-flex justify-content-between align-items-center"> -->
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="card-title m-0">Doctor's History</h4>
          <JsonExcel
            class="btn p-0"
            :data="content"
            name="content.csv"
            type="csv"
          >
            <i class="mdi mdi-download"></i>
            Download
          </JsonExcel>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <!-- Search -->
            <div>
            <label class="d-flex align-items-center">
                <div style="width: 100%;">Search By Doctor's Name:</div>
                <b-form-input
                v-model="doctorName"
                v-on:keyup="searchByDoctor"
                @search="resetFilters"
                type="search"
                class="form-control ml-2"
                ></b-form-input>
            </label>
            </div>
            <div>
            <label class="d-flex align-items-center">
                <div style="width: 100%;">Search By Mobile Number:</div>
                <b-form-input
                v-model="mobileNumber"
                v-on:keyup="searchByMobile"
                @search="resetFilters"
                type="search"
                class="form-control ml-2"
                ></b-form-input>
            </label>
            </div>
            <!-- End search -->
        </div>
        <template v-if="isSearched == true">
            <div class="table-responsive">
                <b-table
                    :items="content"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered">
                    <!-- <template v-slot:cell(title)="row">
                        <div>{{row.item.title}}</div>
                    </template> -->
                    <template v-slot:cell(title)="row">
                        <b>
                            <a v-if="row.item.type == 'Video'"
                                :href="'https://mymedisage.com/video/' + row.item.url_link" 
                                target="_blank" 
                                style="color: inherit">
                                {{row.item.title}}
                            </a>
                            <a v-if="row.item.type == 'Article'"
                                :href="'https://mymedisage.com/news/' + row.item.article_id" 
                                target="_blank" 
                                style="color: inherit">
                                {{row.item.title}}
                            </a>
                            <a v-if="row.item.type == 'Case'"
                                :href="'https://mymedisage.com/cases/' + row.item.case_id" 
                                target="_blank" 
                                style="color: inherit">
                                {{row.item.title}}
                            </a>
                            <a v-if="row.item.type == 'Newsletter'"
                                :href="'https://d3qziwubca5qnz.cloudfront.net/documents/' + row.item.file_name" 
                                target="_blank" 
                                style="color: inherit">
                                {{row.item.title}}
                            </a>
                        </b>
                    </template>
                </b-table>
            </div>
            <div class="row">
            <div class="col">
                <div class="dataTables_pa ginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    ></b-pagination>
                </ul>
                </div>
            </div>
            </div>
        </template>
        <template v-else>
            <div class="text-center font-weight-bold">No Results to show</div>
        </template>
      </div>
    </div>
  </template>
    <script>
  import appConfig from "@/app.config";
  import filterMixins from "../../../mixins/filterData";
  import JsonExcel from "vue-json-excel";
  import moment from "moment";
  export default {
    props: ["filters"],
    mixins: [filterMixins],
    components: {
      JsonExcel,
    },
    data() {
      return {
        content: [],
        isSearched: false,
        doctorName: "",
        mobileNumber: null,
        overallCount: false,
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50],
        filter: null,
        filterOn: [],
        sortBy: "cities",
        sortDesc: false,
        fields: [
          { key: "doctor", label: "Doctor" },
          { key: "first_seen_at",formatter: value => {return moment(String(value)).format('Do MMM YYYY')}, label: "First Seen At" },
          { key: "type", label: "Type" },
          { key: "title", label: "Title"}
        ],
      };
    },
    watch: {
      filters: {
        deep: true,
        handler() {
          this.getDoctorHistoryList();
        },
      },
      // overallCount() {
      //   this.getCityList();
      // }
    },
    computed: {
      /* Total no. of records */
      rows() {
        return this.content.length;
      },
    },
    methods: {
        searchByDoctor(event) {
            if (this.doctorName.length >= 3) {
                if (event.keyCode == 13) {
                this.getDoctorHistoryList();
                }
            }
            else if (this.doctorName.length == 0 ) {
                this.isSearched = false;
            }
        },
        searchByMobile(event) {
            if (this.mobileNumber.length >= 3) {
                if (event.keyCode == 13) {
                this.getDoctorHistoryList();
                }
            }
            else if (this.mobileNumber.length == 0 ) {
                this.isSearched = false;
            }
        },
        resetFilters() {
            this.isSearched = false;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getDoctorHistoryList() {
        const searchfiltersParams = this.getCleanFilteredData(this.filters);
        try {
          const response = await this.$http.get(
            appConfig.api_base_url + "/forum/content-viewers",
            {
              params: searchfiltersParams,
            }
          );
          this.content = response.data.viewers;
          this.totalRows = this.content.length;
          this.isSearched = true;
        } catch (err) {
          console.log(err);
        }
        },
    },
    mounted() {
    //   this.getCityList();
    },
  };
  </script>
    